<template>
  <div
    class="
      doc-container
      border-radius-6
      padding-20
      border-radius-6
      box-shadow-light-grey
      height-calc-type1
    "
  >
    <c-table
      :dataList="dataList"
      :rowList="rowList"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :isUseCheckbox="true"
      operationWidth="200"
      height="calc(100vh - 220px)"
      @pageChange="pageChange"
      @selectChange="selectChange"
    >
      <template #filter>
        <el-button @click="initData" class="margin-right-ten"
          >刷新数据</el-button
        >
        <el-select
          v-model="params.adminUserId"
          placeholder="发布人"
          style="width: 150px"
          class="margin-right-ten"
          clearable
          filterable
          @change="initData"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          style="width: 300px"
          class="margin-right-ten"
          v-model="params.content"
          placeholder="请输入文案内容"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </el-input>
        <el-button type="primary" @click="addDoc" class="margin-right-ten"
          >新增文案</el-button
        >
        <el-button
          type="danger"
          @click="delGroup"
          :disabled="selection.length === 0"
          >批量删除</el-button
        >
      </template>
      <template #buttonOperation="{ row }">
        <el-button
          type="primary"
          class="button-small"
          size="small"
          @click="editItem(row)"
          >编辑</el-button
        >
        <el-button
          type="danger"
          class="button-small"
          size="small"
          @click="delItem(row)"
          >删除</el-button
        >
      </template>
    </c-table>
    <!-- <c-button type='danger' :autoConfirm='true' @confirm='testConfirm'>测试</c-button>
    <d-table :dataList="fakeData"
    :rowList="rowList2"
    height='500px'
    :border='true'
    :loading="false"
    :tagInfo="tagInfo"
    :isUseCheckbox='true'
    @handleEvent='handleEvent'
    @sort-change='sortChange'
    @selection-change='selectionChange'
    >
      <template #bindCount='{row}'>
        {{row.bindCount}}caoniwrwer
      </template>
      <template #operation='{row}'>
        <el-button @click='handleEdit(row)' size='small' class='button-small'>编辑</el-button>
        <el-button type='danger' size='small' class='button-small'>删除</el-button>
      </template>
    </d-table> -->
    <el-dialog
      width="450px"
      append-to-body
      :title="`${isEdit ? '编辑' : '新增'}文案`"
      :visible.sync="showEditDialog"
      :before-close="handleCancel"
    >
      <div class="flex-row-start-center">
        <span>文案内容：</span>
        <el-input
          type="textarea"
          placeholder="请输入文案内容"
          style="width: 300px"
          v-model="content"
        ></el-input>
      </div>
      <div class="button-list">
        <el-button type="primary" @click="handleOk">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDocWareList,
  addDocWareItem,
  editDocWareItem,
  delDocWareItem,
  getDocWareDetail,
  delBatchDocWare
} from '@/api/material'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      page: 1,
      total: 0,
      dataList: [],
      params: {},
      content: '',
      pageSize: 15,
      isEdit: false,
      selection: [],
      id: null,
      loading: false,
      adminUserName: '',
      showEditDialog: false,
      rowList: [
        {
          label: 'id',
          prop: 'id',
          width: 50
        },
        {
          label: '文案内容',
          prop: 'content',
          popover: true,
          width: 200
        },
        {
          label: '绑定素材',
          prop: 'bindCount'
        },
        {
          label: '上传者',
          prop: 'adminUserName'
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        }
      ],
      rowList2: [
        {
          label: 'id',
          prop: 'id',
          width: 50
        },
        {
          label: '文案内容',
          children: [
            {
              label: '二级文案',
              prop: 'content',
              width: '200',
              popover: true
            },
            {
              label: '三级文案',
              prop: 'content',
              width: '150',
              popover: true
            }
          ]
        },
        {
          label: '上传者',
          prop: 'adminUserName',
          renderHeader: () => {
            return <span>这是一个自定义标题</span>
          }
        },
        {
          label: '状态',
          prop: 'status',
          switch: true,
          width: '150'
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          sortable: true,
          width: '200'
        },
        {
          label: '更新时间',
          prop: 'updateTime'
        },
        {
          label: '绑定素材',
          prop: 'bindCount',
          slot: true
        },
        {
          label: '链接',
          prop: 'link',
          link: true,
          width: '200'
        },
        {
          label: '标签',
          prop: 'msgType',
          tag: true
        },
        {
          label: '操作',
          action: 'operation',
          fixed: 'right',
          width: '200'
        }
      ],
      tagInfo: [
        {
          tagName: 'msgType',
          tagVal: [
            {
              val: 'text',
              text: '文本'
            },
            {
              val: 'news',
              text: '图文'
            },
            {
              val: 'voice',
              text: '音频'
            },
            {
              val: 'image',
              text: '图片'
            }
          ]
        },
        {
          tagName: 'status',
          tagVal: [
            {
              val: 0,
              text: '否'
            },
            {
              val: 1,
              text: '是'
            }
          ]
        }
      ],
      fakeData: [
        {
          adminUserName: '麦克航',
          adminUserId: 218,
          bindCount: 5,
          content:
            '飞机失事,和美女们流落荒岛。10年后救援到来，众人看到眼前一幕当场惊呆了...',
          createdTime: '2021-06-23 19:56:52',
          id: 64,
          link: 'https://baidu.com',
          status: 1,
          msgType: 'text'
        },
        {
          adminUserName: '测试名字',
          adminUserId: 142,
          bindCount: 115,
          content: '10年后救援到来，众人看到眼前一幕当场惊呆了',
          createdTime: '2021-1-1',
          id: 43,
          link: 'https://www.zhihu.com/search?type=content&q=vue%20jsx%20%E5%86%99%E6%B3%95',
          status: 0,
          msgType: 'news'
        }
      ],
      customValue: ''
    }
  },
  computed: {
    ...mapGetters(['userList'])
  },
  mounted () {
    this.initData()
  },
  watch: {},
  methods: {
    initData () {
      this.loading = true
      getDocWareList({
        page: this.page,
        pageSize: this.pageSize,
        ...this.params
      })
        .then((res) => {
          this.total = res.total
          this.dataList = res.list
        })
        .finally(() => {
          this.loading = false
        })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    selectChange (select) {
      this.selection = select
    },
    editItem (row) {
      this.isEdit = true
      this.showEditDialog = true
      this.content = row.content
      this.id = row.id
    },
    handleOk () {
      if (!this.content) return this.$message.warning('文案内容不能为空!')
      if (!this.isEdit) {
        addDocWareItem(this.content).then(() => {
          this.$message.success('新增成功!')
          this.initData()
          this.handleCancel()
        })
      } else {
        editDocWareItem(this.id, this.content).then(() => {
          this.$message.success('修改成功!')
          this.initData()
          this.handleCancel()
        })
      }
    },
    addDoc () {
      this.isEdit = false
      this.content = ''
      this.showEditDialog = true
      this.id = null
    },
    delGroup () {
      this.$confirm('确定删除所选内容吗？删除的内容将不可恢复', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          const ids = this.selection.map((item) => item.id)
          delDocWareItem(ids).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    handleCancel () {
      this.content = ''
      this.showEditDialog = false
      this.isEdit = false
      this.id = null
    },

    delItem (row) {
      this.$confirm('确定要删除吗？删除后的文件将不可恢复', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          delDocWareItem(row.id).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang='scss' scoped>
.doc-container {
  background: #fff;
}
.button-list {
  margin-top: 20px;
  text-align: center;
}
</style>
